import React, { useEffect, useRef, useState } from 'react'
import { ITextInput } from './TextInput.model'
import './TextInput.scss'

const TextInput: React.FC<ITextInput> = ({ placeholder, value, changeField, type, maxLength, disabled = false }) => {
	const [textAreaHeight, setTextAreaHeight] = useState<number>(16)
	const [lastTaHeight, setLastTaHeight] = useState<number>(16)
	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (textAreaRef.current) {
			if (value) {
				const scrollHeight = textAreaRef.current.scrollHeight
				const delta = scrollHeight - lastTaHeight

				setLastTaHeight(scrollHeight)
				setTextAreaHeight(textAreaHeight + delta)
			} else {
				textAreaRef.current.style.height = '0px'
				textAreaRef.current.removeAttribute('style')
			}
		}
	}, [value])

	return (
		<div className='text-input__label'>
			{type === 'input' && (
				<input
					type='text'
					value={value}
					className='text-input__field'
					disabled={disabled}
					required
					maxLength={maxLength}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeField(e.target.value)}
				/>
			)}
			{type === 'textarea' && (
				<textarea
					ref={textAreaRef}
					value={value}
					className='text-input__field'
					disabled={disabled}
					maxLength={maxLength}
					required
					style={{ height: textAreaHeight }}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => changeField(e.target.value)}
				/>
			)}
			<label className='text-input__placeholder'>{placeholder}</label>
		</div>
	)
}

export { TextInput }
