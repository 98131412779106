import React, { useEffect, useState } from 'react'
import { floatFormat, spaceFormat } from '../../../helpers/helperInputFloat'
import { ISpetification } from '../../../redux/actions/projectsAction/types'

interface IRMRowTable {
	spec: ISpetification
	specArr: ISpetification[]
	setSpecArr(specArr: ISpetification[]): void
	createSpecifications(body: ISpetification): void
	deleteSpecifications(id: number | string, projectId: number): void
	updateSpecifications(body: ISpetification): void
	indexNewItem: number
}

const RMRowTable: React.FC<IRMRowTable> = ({
	spec,
	createSpecifications,
	deleteSpecifications,
	updateSpecifications,
	specArr,
	setSpecArr,
	indexNewItem,
}) => {
	const [solutionName, setSolutionName] = useState<string>(spec.solution_name)
	const [specifications, setSpecifications] = useState<string>(spec.specifications)
	const [price, setPrice] = useState<string | number>(spaceFormat(+spec.price / 1000))

	const createBody: ISpetification = {
		project_id: spec.project_id,
		solution_name: solutionName,
		specifications,
		price: floatFormat(+price * 1000),
	}

	const updateBody: ISpetification = { ...createBody, id: spec.id }

	return (
		<div className='projects-table__body-row projects-rm__item row'>
			<div
				className='close-icon projects-rm__item-delete'
				title='Удалить характеристику'
				onClick={() => {
					if (spec.id && spec.project_id) {
						deleteSpecifications(spec.id, spec.project_id)
					}
					if (!spec.id) {
						const newArr = specArr.slice().filter((_, index) => index !== indexNewItem)
						setSpecArr(newArr)
					}
				}}
			></div>
			<div className='projects-table__body-item col-4'>
				<input
					type='text'
					className='table-input'
					placeholder='Строительные работы'
					value={solutionName}
					onBlur={() => {
						if (!spec.id && solutionName && specifications && price) {
							createSpecifications(createBody)
						}
						if (spec.id && solutionName && specifications && price && solutionName !== spec.solution_name) {
							updateSpecifications(updateBody)
						}
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSolutionName(e.target.value)}
				/>
			</div>
			<div className='projects-table__body-item col-4'>
				<input
					type='text'
					className='table-input'
					placeholder='Открытое распределительное устройство (ОРУ-500 кВ)'
					value={specifications}
					onBlur={() => {
						if (!spec.id && solutionName && specifications && price) {
							createSpecifications(createBody)
						}
						if (spec.id && solutionName && specifications && price && specifications !== spec.specifications) {
							updateSpecifications(updateBody)
						}
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSpecifications(e.target.value)}
				/>
			</div>
			<div className='projects-table__body-item col-4'>
				<input
					type='text'
					className='table-input'
					placeholder='0.00'
					value={price}
					onFocus={e => setPrice(floatFormat(e.target.value))}
					onBlur={e => {
						if (!spec.id && solutionName && specifications && price) {
							createSpecifications(createBody)
						}
						if (spec.id && solutionName && specifications && price && price !== spec.price) {
							updateSpecifications(updateBody)
						}
						setPrice(spaceFormat(e.target.value))
					}}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(floatFormat(e.target.value))}
				/>
			</div>
		</div>
	)
}

export default RMRowTable
