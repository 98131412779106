import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { IUploaderFile } from './models/uploaderFile'
import './UploaderFile.scss'

const UploaderFile: React.FC<IUploaderFile> = ({
	uploadedFile,
	fieldTitle,
	uploadHandler,
	deleteHandler,
	nameField,
	downloadHandler,
}) => {
	const inputVal = useRef<HTMLInputElement>(null)

	return (
		<div
			className={`file flex-between ${uploadedFile ? 'no-empty' : ''}`}
			onDragOver={e => e.preventDefault()}
			onDragLeave={(e: any) => {
				if (e.relatedTarget.nodeType === 3 || e.relatedTarget.closest('.file')) return
			}}
			onDrop={e => {
				e.preventDefault()
				if (uploadHandler && e.dataTransfer.files && nameField) {
					const ext = e.dataTransfer.files[0].name.split('.')

					if (ext[ext.length - 1] === 'xlsx' || ext[ext.length - 1] === 'xls') {
						uploadHandler(nameField, e.dataTransfer.files[0])
					} else {
						toast.error('Неверный формат файла. Допустимые форматы - ".xlsx" и ".xls"')
						return
					}
				}
			}}
			onClick={() => (!uploadedFile ? inputVal.current?.click() : null)}
		>
			{uploadedFile ? (
				<>
					<div className='file__uploaded' onClick={downloadHandler} title='Скачать файл'>
						<span className='file__uploaded-name color-blue'>{fieldTitle}</span>&nbsp;загружен
					</div>
					<div className='file__actions flex-between'>
						<div className='file__edit edit-icon' onClick={() => inputVal.current?.click()} />
						{deleteHandler && (
							<div
								className='file__delete basket-icon'
								onClick={() => {
									if (deleteHandler) deleteHandler()
								}}
							/>
						)}
					</div>
				</>
			) : (
				<span className='file__uploaded-name text-align-center color-blue'>{fieldTitle}</span>
			)}
			<input
				ref={inputVal}
				type='file'
				accept='.xls, .xlsx'
				onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
					if (uploadHandler && e.currentTarget.files && nameField) {
						const ext = e.currentTarget.files[0].name.split('.')

						if (ext[ext.length - 1] === 'xlsx' || ext[ext.length - 1] === 'xls') {
							uploadHandler(nameField, e.currentTarget.files[0])
						} else {
							toast.error('Неверный формат файла. Допустимые форматы - ".xlsx" и ".xls"')
							return
						}
					}
				}}
			/>
		</div>
	)
}

export { UploaderFile }
