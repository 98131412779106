import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProjectsRM from '../../components/Projects/ProjectsRM/ProjectsRM'
import {
	createSpecifications,
	deleteSpecifications,
	editInputOption,
	getInputOptions,
	getRm,
	getSelectProject,
	getSpecifications,
	updateSpecifications,
} from '../../redux/actions'
import { ISpetification } from '../../redux/actions/projectsAction/types'
import { IState } from '../../redux/redusers/rootReducer/state.model'

const ProjectsRmContainer: React.FC<any> = ({}) => {
	const { id } = useParams<{ id: string }>()
	const dispatch = useDispatch()
	const { selectedProject, inputOptions, specifications } = useSelector((state: IState) => ({
		selectedProject: state.projects.selectedProject,
		inputOptions: state.projects.inputOptions,
		specifications: state.projects.specifications,
	}))

	useEffect(() => {
		if (!inputOptions.length) dispatch(getInputOptions())
	}, [])

	useEffect(() => {
		if (!selectedProject) dispatch(getSelectProject({ id: +id }))
	}, [])

	useEffect(() => {
		if (!specifications.length) dispatch(getSpecifications({ id: +id }))
	}, [])

	return (
		selectedProject && (
			<ProjectsRM
				createSpecifications={(body: ISpetification) => dispatch(createSpecifications(body))}
				deleteSpecifications={(id: number, projectId: number) => dispatch(deleteSpecifications({ id, projectId }))}
				updateSpecifications={(body: ISpetification) => dispatch(updateSpecifications(body))}
				specifications={specifications}
				inputOptions={inputOptions}
				selectedProject={selectedProject}
				editOption={(key: string, id: number) => dispatch(editInputOption({ key, id }))}
				getRM={() => dispatch(getRm({ code: selectedProject?.ncs_code, id: selectedProject.id }))}
			/>
		)
	)
}

export default ProjectsRmContainer
