import { ProjectsType } from '../../actions/projectsAction/types'
import { IProjectsState } from './models/projectsState'

const initialState: IProjectsState = {
	projects: [],
	loading: false,
	strSearch: '',
	allowMoreProjects: true,
	selectedProject: null,
	inputOptions: [],
	specifications: [],
}

export const projectsReducer = (state = initialState, action: any) => {
	switch (action.type) {
		case ProjectsType.SET_PROJECTS:
			return {
				...state,
				projects: action.payload,
			}
		case ProjectsType.SET_PROJECT_EDIT:
			return {
				...state,
				selectedProject: action.payload,
			}
		case ProjectsType.LOADING_PROJECTS:
			return {
				...state,
				loading: action.payload,
			}
		case ProjectsType.ALLOW_MORE_PROJECTS:
			return {
				...state,
				allowMoreProjects: action.payload,
			}
		case ProjectsType.SET_SEARCH:
			return {
				...state,
				strSearch: action.payload,
			}
		case ProjectsType.SET_INPUT_OPTIONS:
			return {
				...state,
				inputOptions: action.payload,
			}
		case ProjectsType.SET_SPECIFICATIONS:
			return {
				...state,
				specifications: action.payload,
			}
		default:
			return state
	}
}
