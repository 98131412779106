import { Reducer } from 'redux'
import { AuthType } from '../../actions'
import { IAuthState } from './auth.model'

const initialState: IAuthState = {
	accessToken: localStorage.getItem('access_token'),
	expires_in: 0,
	user: JSON.parse(localStorage.getItem('user') as string),
}

export const authReducer: Reducer<IAuthState> = (state = initialState, action) => {
	switch (action.type) {
		case AuthType.SET_AUTH:
			return {
				...state,
				accessToken: action.accessToken,
				expires_in: action.expires_in,
				user: action.user,
			}
		default:
			return state
	}
}
