import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../components/Projects/Table/Table'
import { appendProjects, getProjects } from '../../redux/actions'
import { IState } from '../../redux/redusers/rootReducer/state.model'
import throttle from '../../helpers/throttle'
import Loader from '../../shared/components/Loader/Loader'

const Projects = () => {
	const dispatch = useDispatch()
	const { projects, loading, allowMoreProjects } = useSelector((state: IState) => state.projects)

	useEffect(() => {
		if (!projects.length) dispatch(getProjects())
	}, [])

	const handleScroll = () => {
		const currentScroll = window.scrollY + window.outerHeight + 100
		if (currentScroll >= document.body.scrollHeight && !loading) {
			dispatch(appendProjects())
		}
	}

	const setThrottle = throttle(handleScroll, 100)

	useEffect(() => {
		if (!loading && allowMoreProjects) {
			document.addEventListener('scroll', setThrottle)
			return () => document.removeEventListener('scroll', setThrottle)
		}
	}, [loading])

	return (
		<>
			<Table projects={projects} /> {loading && <Loader />}
		</>
	)
}

export default Projects
