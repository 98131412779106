import { combineReducers } from 'redux'
import { authReducer } from '../authReducer/authReducer'
import { projectsReducer } from '../projectsReducer/projectsReducer'
import { IState } from './state.model'

const rootReducer = combineReducers<IState>({
	auth: authReducer,
	projects: projectsReducer,
})

export { rootReducer }
