const regexInputNcsCode = (str: string): string => {
	if (!str) return ''
	const regex = /(\d{2}?)(\d{2})(\d{3})(\d{2})$/g
	const substr = '$1-$2-$3-$4'

	str = str
		.replace(/[^\d]/g, '')
		.split('')
		.map((element: string, index: number) => {
			switch (index) {
				case 1:
					return element + '-'
				case 3:
					return element + '-'
				case 6:
					return element + '-'
			}
			return element
		})
		.join('')

	return str.substring(0, 12).replace(regex, substr)
}

export default regexInputNcsCode
