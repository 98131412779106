import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router, Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Header, ProjectEdit, ProjectEditHeader, Projects, ProjectsRM } from './containers'
import { authorize } from './redux/actions'
import { IState } from './redux/redusers/rootReducer/state.model'
import { history, Routes } from './router'
import 'react-toastify/dist/ReactToastify.css'
import './shared/styles/main.scss'

const App = () => {
	const { token } = useSelector((state: IState) => ({
		token: state.auth.accessToken,
	}))

	const dispatch = useDispatch()

	useEffect(() => {
		if (!token) dispatch(authorize())
	}, [token])
	return (
		<Router history={history}>
			<Header>
				<Route path={Routes.selectedProject} component={ProjectEditHeader} />
			</Header>
			{token && (
				<>
					<Route exact path='/' render={() => <Redirect to={Routes.projects} />} />
					<Route exact path={Routes.projects} component={Projects} />
					<Route exact path={Routes.selectedProject} component={ProjectEdit} />
					<Route exact path={Routes.selectedProjectRm} component={ProjectsRM} />
				</>
			)}
			<ToastContainer position="bottom-left" autoClose={5000} />
		</Router>
	)
}

export default App
