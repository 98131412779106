import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { formatDate } from '../../../helpers/formatDate'
import { formatUserName } from '../../../helpers/formatUserName'
import regexInputNcsCode from '../../../helpers/regexInputNcsCode'
import throttle from '../../../helpers/throttle'
import { createProject, getSelectProject, projectsSearch, setSearch } from '../../../redux/actions'
import { Button, Modal, TextInput } from '../../../shared/components'
import { ITable } from './models/table'
import './Table.scss'

const Table = ({ projects }: ITable) => {
	const [showModal, setShowModal] = useState<boolean>(false)
	const [titleProject, setTitleProject] = useState<string>('')
	const [strSearch, setStrSearch] = useState<string>('')
	const [code, setCode] = useState<string>('')
	const dispatch = useDispatch()

	const submitCreateProject = () => {
		dispatch(createProject({ ncs_code: regexInputNcsCode(code), name: titleProject }))
		setShowModal(false)
		setTitleProject('')
		setCode('')
	}

	const changeStrSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStrSearch(e.target.value)
		dispatch(setSearch(e.target.value))
		dispatch(projectsSearch({ find: e.target.value }))
	}

	return (
		<div className='projects container'>
			<div className='projects-actions'>
				<form className='projects__search'>
					<button type='submit' className='projects__search-btn search-icon' />
					<input
						type='text'
						className='projects__search-input'
						value={strSearch}
						onChange={throttle(changeStrSearch, 1000)}
					/>
				</form>
				<Button text='Добавить' onClick={() => setShowModal(true)} />
			</div>
			{showModal && (
				<Modal
					title='Новый проект'
					disableSubmit={code.length < 12 || !titleProject}
					setShowModal={setShowModal}
					submitText='Создать'
					onSubmit={submitCreateProject}
				>
					<TextInput
						type='textarea'
						placeholder='Название проекта'
						value={titleProject}
						changeField={setTitleProject}
					/>
					<TextInput type='input' placeholder='Код показателя' value={regexInputNcsCode(code)} changeField={setCode} />
				</Modal>
			)}
			{Boolean(projects.length) && (
				<div className='projects-table'>
					<div className='projects-table__head row'>
						<div className='projects-table__head-item col-2'>Код показателя</div>
						<div className='projects-table__head-item col-4'>Название проекта</div>
						<div className='projects-table__head-item col-1 text-align-right'>Год НЦС</div>
						<div className='projects-table__head-item col-1 text-align-right'>Объекты</div>
						<div className='projects-table__head-item col-2'>Исполнитель</div>
						<div className='projects-table__head-item col-2 text-align-right'>Дата публикации</div>
					</div>
					<div className='projects-table__body'>
						{projects
							.filter(project => project)
							.map(({ id, name, created_at, ncs_code, object_count, user_initials, year }) => (
								<div
									className='projects-table__body-row row'
									key={id}
									onClick={() => dispatch(getSelectProject({ id }))}
								>
									<div className='projects-table__body-item col-2'>{ncs_code}</div>
									<div className='projects-table__body-item col-4'>{name}</div>
									<div className='projects-table__body-item col-1 text-align-right'>{year}</div>
									<div className='projects-table__body-item col-1 text-align-right'>{object_count}</div>
									<div className='projects-table__body-item col-2'>{formatUserName(user_initials)}</div>
									<div className='projects-table__body-item col-2 text-align-right'>{formatDate(created_at)}</div>
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	)
}

export { Table }
