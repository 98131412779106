import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProjectEdit from '../../components/Projects/ProjectEdit/ProjectEdit'
import { deleteProject, getSelectProject } from '../../redux/actions'
import { IState } from '../../redux/redusers/rootReducer/state.model'

const ProjectEditContainer: React.FC = () => {
	const { id } = useParams<{ id: string }>()
	const dispatch = useDispatch()
	const selectedProject = useSelector((state: IState) => state.projects.selectedProject)
	useEffect(() => {
		if (!selectedProject) dispatch(getSelectProject({ id: +id }))
	}, [])

	return (
		selectedProject && (
			<ProjectEdit deleteProject={() => dispatch(deleteProject({ id: +id }))} selectedProject={selectedProject} />
		)
	)
}

export default ProjectEditContainer
