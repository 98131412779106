import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LOCATION_GIPRO, logoutApi } from '../../../api'
import { IAvatar } from './Avatar.model'
import './Avatar.scss'

const Avatar: React.FC<IAvatar> = () => {
	const [open, setOpen] = useState<boolean>(false)

	const openMenu = () => {
		setOpen(true)
	}

	const wrapper = useRef<HTMLDivElement>(null)

	const outsideClickHandler = useCallback((): void => {
		if (open) {
			setOpen(false)
		}
	}, [open])

	useEffect(() => {
		document.addEventListener('click', outsideClickHandler)
		return () => document.removeEventListener('click', outsideClickHandler)
	}, [outsideClickHandler])

	const moveToGIPRO = () => (window.location.href = LOCATION_GIPRO)

	const logout = () => logoutApi()

	return (
		<div className='avatar'>
			<img className='avatar__img' src='/images/svg/avatar-default.svg' alt='Avatar' onClick={openMenu} />
			{open && (
				<div className='popup' ref={wrapper}>
					<div className='avatar__menu'>
						<div className='avatar__menu-item' onClick={moveToGIPRO}>
							Перейти в GIPRO
						</div>
						<div className='avatar__menu-item' onClick={logout}>
							Выход
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export { Avatar }
