import { all } from 'redux-saga/effects'
import watchAuthorize from '../authSaga/authSaga'
import watchFilesSaga from '../filesSaga/filesSaga'
import watchGetProjects from '../projectsSaga/projectsSaga'

function* rootSaga() {
	yield all([watchAuthorize(), watchGetProjects(), watchFilesSaga()])
}

export { rootSaga }
