import React from 'react'
import { Avatar } from '../../components/Header/Avatar'
import './Header.scss'

const Header: React.FC = ({children}) => {
	return (
		<header className='header'>
			{children}
			<Avatar />
		</header>
	)
}

export default Header
