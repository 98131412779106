export const formatDate = (str: string | Date, send = false) => {
	if (!str) return null
	const date: Date = send ? new Date(str.toString().split('.').reverse().join('-')) : new Date(str)

	let dd: string | number = date.getDate()
	if (dd < 10) dd = '0' + dd

	let mm: string | number = date.getMonth() + 1
	if (mm < 10) mm = '0' + mm

	let yy: string | number = date.getFullYear()
	if (yy < 10) yy = '0' + yy

	if (send) {
		return yy + '-' + mm + '-' + dd
	}

	return dd + '.' + mm + '.' + yy
}
