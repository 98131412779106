import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'
import { authorizeApi, modeURL, logoutApi, ncsApp } from '../../../api'
import { AuthType, setAuth } from '../../actions'

function* authorize() {
	try {
		const authorize = yield call(authorizeApi)

		if (authorize && authorize.status === 'success') {
			yield put(setAuth(authorize.access_token, authorize.expires_in, authorize.user))
			yield localStorage.setItem('access_token', authorize.access_token)
			yield localStorage.setItem('user', JSON.stringify(authorize.user))
			yield (ncsApp.defaults.headers['Authorization'] = `Bearer ${authorize.access_token}`)
		} else {
			yield localStorage.removeItem('access_token')
			yield localStorage.removeItem('user')
			yield (window.location.href = modeURL)
		}
		toast.success('Успешный вход. Добро пожаловать!')
	} catch (error) {
		toast.error('Ошибка авторизации')
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield localStorage.removeItem('user')
		yield (window.location.href = modeURL)
	}
}

function* logout() {
	try {
		const res = yield call(logoutApi)

		if (res && res.status === 'success') {
			yield localStorage.removeItem('access_token')
			yield localStorage.removeItem('user')
			yield (window.location.href = modeURL)
		}
	} catch (error) {
		console.log(error)
		yield localStorage.removeItem('access_token')
		yield localStorage.removeItem('user')
		yield (window.location.href = modeURL)
	}
}

export default function* watchAuthorize() {
	yield takeLatest(AuthType.AUTHORIZE, authorize)
	yield takeLatest(AuthType.LOGOUT, logout)
}
