import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
	getProjectsApi,
	createProjectApi,
	selectProjectApi,
	deleteProjectApi,
	updateProjectApi,
	searchProjectsApi,
	getRmApi,
	getOptionsApi,
	getSpecificationsApi,
	createSpecificationsApi,
	deleteSpecificationsApi,
	updateSpecificationsApi,
} from '../../../api'
import { ISpetification, ProjectsType } from '../../actions/projectsAction/types'
import {
	getSelectProject,
	setAllowMoreProjects,
	setInputOptions,
	setLoadingProjects,
	setProjects,
	setSelectedProject,
	setSpecifications,
} from '../../actions/projectsAction/projectsAction'
import store from '../../../store'
import { history, Routes } from '../../../router'
import validateObject from '../../../helpers/validateObjects'
import { toast } from 'react-toastify'

function* getProjects() {
	try {
		yield put(setLoadingProjects(true))
		const params = {
			limit: 15,
			offset: 0,
		}
		const projects = yield call(getProjectsApi, params)
		yield put(setProjects(projects.data))
	} catch (error) {
		yield toast.error('Ошибка загрузки проектов!')
		console.log(error)
	} finally {
		yield put(setLoadingProjects(false))
	}
}

function* appendProjects() {
	try {
		yield put(setLoadingProjects(true))
		let { strSearch, projects } = store.getState().projects

		projects = projects.slice()
		let params = {
			find_name: strSearch,
			limit: 15,
			offset: projects.length,
		}

		const res: any = yield call(getProjectsApi, validateObject(params))

		if (res && res.status === 'success') {
			projects = [...projects, ...res.data]

			yield put(setProjects(projects))
			if (!res.data.length || res.data.length < 15) {
				yield put(setAllowMoreProjects(false))
			}
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(setLoadingProjects(false))
	}
}

function* searchProject({ payload }: any) {
	try {
		yield put(setLoadingProjects(true))
		yield put(setAllowMoreProjects(true))

		const params = {
			...payload,
			limit: 15,
			offset: 0,
		}
		const { status, data } = yield call(searchProjectsApi, params)

		if (status === 'success') {
			yield put(setProjects(data))
		}

		if (!data.length || data.length < 15) {
			yield put(setAllowMoreProjects(false))
		}
	} catch (error) {
		console.log(error)
	} finally {
		yield put(setLoadingProjects(false))
	}
}

function* createProject({ payload }: any) {
	try {
		const projects = store.getState().projects.projects.slice()
		const newProject = yield call(createProjectApi, payload)

		if (newProject.status === 'success') {
			projects.push(newProject.data)
			yield put(setProjects(projects))
			toast.success(`Проект ${newProject.data.ncs_code} создан!`)
		} else {
			throw new Error()
		}
	} catch (error) {
		toast.error(`Произошла ошибка при создании проекта`)
		console.log(error)
	}
}

function* updateProject({ payload }: any) {
	try {
		const projects = store.getState().projects.projects
		const body = {
			ncs_code: payload.ncs_code,
			name: payload.name,
		}
		const { data } = yield call(updateProjectApi, body, payload.id)
		const idx = projects.findIndex(elem => elem.id === payload.id)
		projects[idx] = data
		yield put(setSelectedProject(data))
		yield put(setProjects(projects))
		toast.success(`Проект ${data.ncs_code} обновлен!`)
	} catch (error) {
		toast.error(`Произошла при обновлении проекта`)
		console.log(error)
	}
}

function* deleteProject({ payload }: any) {
	try {
		const projects = store.getState().projects.projects
		const { status } = yield call(deleteProjectApi, payload.id)
		if (status === 'success') {
			const idx = projects.findIndex(elem => elem.id === payload.id)
			const code = projects[idx].ncs_code
			projects.splice(idx, 1)
			yield put(setProjects(projects))
			yield history.push(Routes.projects)
			toast.warn(`Проект ${code} удален!`)
		}
	} catch (error) {
		toast.error(`Произошла при удалении проекта`)
		console.log(error)
	}
}

function* selectProject({ payload }: any) {
	try {
		const { status, data } = yield call(selectProjectApi, payload.id)

		if (status === 'success') {
			yield put(setSelectedProject(data))
			const isRM: boolean = window.location.pathname.includes('rm')
			if (isRM) {
				yield history.push(`/projects/${data.id}/rm`)
			} else {
				yield history.push(`/projects/${data.id}`)
			}
		}
	} catch (error) {
		toast.error(error)
		console.log(error)
	}
}

function* getRm({ payload }: any) {
	try {
		yield call(getRmApi, payload.id, payload.code)
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* getInputOptions() {
	try {
		const res = yield call(getOptionsApi)
		yield put(setInputOptions(res.data))
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* editOptions({ payload }: any) {
	try {
		const projectId = store.getState().projects.selectedProject?.id
		const body = {
			[payload.key]: payload.id,
		}
		if (projectId) {
			yield call(updateProjectApi, body, projectId)
			yield toast.success('Опция изменена!')
		}

		if (projectId) yield getSelectProject({ id: projectId })
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* getSpecifications({ payload }: any) {
	try {
		const res = yield call(getSpecificationsApi, payload.id)
		yield put(setSpecifications(res.data))
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* createSpecifications({ payload }: any) {
	try {
		const body: ISpetification = {
			...payload,
		}

		delete body.project_id
		yield call(createSpecificationsApi, payload.project_id, body)

		yield toast.success('Характеристика добавлена')
		yield getSpecifications({ payload: { id: payload.project_id } })
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* deleteSpecifications({ payload }: any) {
	try {
		yield call(deleteSpecificationsApi, payload.id, payload.projectId)
		yield getSpecifications({ payload: { id: payload.projectId } })

		yield toast.warn('Характеристика удалена')
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

function* updateSpecifications({ payload }: any) {
	try {
		const body: ISpetification = {
			...payload,
		}

		delete body.project_id
		yield call(updateSpecificationsApi, payload.project_id, body)

		yield toast.info('Характеристика обновлена')
		yield getSpecifications({ payload: { id: payload.project_id } })
	} catch (error) {
		toast.error('Произошла ошибка')
		console.log(error)
	}
}

export default function* watchGetProjects() {
	yield takeEvery(ProjectsType.GET_PROJECTS, getProjects)
	yield takeLatest(ProjectsType.CREATE_PROJECT, createProject)
	yield takeEvery(ProjectsType.GET_SELECT_PROJECT, selectProject)
	yield takeEvery(ProjectsType.DELETE_PROJECT, deleteProject)
	yield takeLatest(ProjectsType.UPDATE_PROJECT, updateProject)
	yield takeLatest(ProjectsType.PROJECTS_SEARCH, searchProject)
	yield takeLatest(ProjectsType.APPEND_PROJECTS, appendProjects)
	yield takeEvery(ProjectsType.GET_RM, getRm)
	yield takeEvery(ProjectsType.GET_INPUT_OPTIONS, getInputOptions)
	yield takeEvery(ProjectsType.EDIT_INPUT_OPTION, editOptions)
	yield takeEvery(ProjectsType.GET_SPECIFICATIONS, getSpecifications)
	yield takeEvery(ProjectsType.CREATE_SPECIFICATIONS, createSpecifications)
	yield takeEvery(ProjectsType.DELETE_SPECIFICATIONS, deleteSpecifications)
	yield takeEvery(ProjectsType.UPDATE_SPECIFICATIONS, updateSpecifications)
}
