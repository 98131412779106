import { createAction } from '@reduxjs/toolkit'
import { IProject } from '../../../components/Projects/Table/models/project'
import { ICreateProject, ISelectedProject, ProjectsType, InputOption, ISpetification } from './types'

const getProjects = createAction(ProjectsType.GET_PROJECTS)
const appendProjects = createAction(ProjectsType.APPEND_PROJECTS)
const setProjects = createAction<IProject[]>(ProjectsType.SET_PROJECTS)
const createProject = createAction<ICreateProject>(ProjectsType.CREATE_PROJECT)
const getSelectProject = createAction<{ id: number }>(ProjectsType.GET_SELECT_PROJECT)
const deleteProject = createAction<{ id: number }>(ProjectsType.DELETE_PROJECT)
const setSelectedProject = createAction<ISelectedProject | null>(ProjectsType.SET_PROJECT_EDIT)
const updateProject = createAction<ICreateProject>(ProjectsType.UPDATE_PROJECT)
const projectsSearch = createAction<{ find: string }>(ProjectsType.PROJECTS_SEARCH)
const setLoadingProjects = createAction<boolean>(ProjectsType.LOADING_PROJECTS)
const setSearch = createAction<string>(ProjectsType.SET_SEARCH)
const setAllowMoreProjects = createAction<boolean>(ProjectsType.ALLOW_MORE_PROJECTS)
const getRm = createAction<{ id: number; code: string }>(ProjectsType.GET_RM)
const getInputOptions = createAction(ProjectsType.GET_INPUT_OPTIONS)
const setInputOptions = createAction<InputOption[]>(ProjectsType.SET_INPUT_OPTIONS)
const editInputOption = createAction<{ key: string; id: number }>(ProjectsType.EDIT_INPUT_OPTION)
const getSpecifications = createAction<{ id: number }>(ProjectsType.GET_SPECIFICATIONS)
const setSpecifications = createAction<ISpetification[]>(ProjectsType.SET_SPECIFICATIONS)
const createSpecifications = createAction<ISpetification>(ProjectsType.CREATE_SPECIFICATIONS)
const deleteSpecifications = createAction<{ id: number; projectId: number }>(ProjectsType.DELETE_SPECIFICATIONS)
const updateSpecifications = createAction<ISpetification>(ProjectsType.UPDATE_SPECIFICATIONS)

export {
	getProjects,
	setProjects,
	createProject,
	getSelectProject,
	setSelectedProject,
	deleteProject,
	updateProject,
	projectsSearch,
	setLoadingProjects,
	setSearch,
	setAllowMoreProjects,
	appendProjects,
	getRm,
	getInputOptions,
	setInputOptions,
	editInputOption,
	getSpecifications,
	setSpecifications,
	createSpecifications,
	deleteSpecifications,
	updateSpecifications,
}
