import React, { useEffect, useState } from 'react'
import { InputOption, ISelectedProject, ISpetification } from '../../../redux/actions/projectsAction/types'
import RMRowTable from './RMRowTable'
import { Button } from '../../../shared/components'
import Select from 'react-select'
import './ProjectsRM.scss'
import { prettyCost } from '../../../helpers/helperInputFloat'

interface IProjectsRM {
	getRM(): void
	inputOptions: InputOption[] | null
	selectedProject: ISelectedProject
	editOption(key: string, id: number): void
	specifications: ISpetification[]
	createSpecifications(body: ISpetification): void
	deleteSpecifications(id: number, projectId: number): void
	updateSpecifications(body: ISpetification): void
}

const ProjectsRM: React.FC<IProjectsRM> = ({
	inputOptions,
	getRM,
	selectedProject,
	editOption,
	specifications,
	createSpecifications,
	deleteSpecifications,
	updateSpecifications,
}) => {
	const [specArr, setSpecArr] = useState(specifications)
	const [valuePrice, setValuePrice] = useState<string>(selectedProject?.price?.toString() || '0')

	useEffect(() => {
		setSpecArr(specifications)
	}, [specifications])

	useEffect(() => {
		let result = specArr?.reduce((sum, elem) => {
			return sum + parseFloat(elem.price.toString())
		}, 0)
		if (result) {
			setValuePrice(result.toString())
		}
	}, [specArr])

	// const styleSelect = {
	// 	control: (base: any) => ({
	// 		...base,
	// 		border: '1px solid #e0e0e0',
	// 		boxRadius: '6px',
	// 		height: '40px',
	// 		fontSize: 14,
	// 	}),
	// 	option: (base: any) => ({
	// 		...base,
	// 		fontSize: 14,
	// 		border: '1px solid #e0e0e0',
	// 	}),
	// }

	// const [valueTB, setValueTB] = useState<any>({
	// 	id: selectedProject?.temporary_buildings.id,
	// 	value: selectedProject?.temporary_buildings.description,
	// 	label: selectedProject?.temporary_buildings.description,
	// })
	// const [valuePNR, setValuePNR] = useState<any>({
	// 	id: selectedProject?.pnr.id,
	// 	value: selectedProject?.pnr.description,
	// 	label: selectedProject?.pnr.description,
	// })
	// const [valuePI, setValuePI] = useState<any>({
	// 	id: selectedProject?.price_increase.id,
	// 	value: selectedProject?.price_increase.description,
	// 	label: selectedProject?.price_increase.description,
	// })

	// const tbSelect =
	// 	inputOptions &&
	// 	inputOptions
	// 		.filter(item => item.type === 'tb')
	// 		.map(elem => ({
	// 			id: elem.id,
	// 			value: elem.description,
	// 			label: elem.description,
	// 		}))
	// const piSelect =
	// 	inputOptions &&
	// 	inputOptions
	// 		.filter(item => item.type === 'pi')
	// 		.map(elem => ({
	// 			id: elem.id,
	// 			value: elem.description,
	// 			label: elem.description,
	// 		}))
	// const pnrSelect =
	// 	inputOptions &&
	// 	inputOptions
	// 		.filter(item => item.type === 'pnr')
	// 		.map(elem => ({
	// 			id: elem.id,
	// 			value: elem.description,
	// 			label: elem.description,
	// 		}))

	// const findedTB = inputOptions?.find(item => item.id === valueTB.id)
	// const findedPI = inputOptions?.find(item => item.id === valuePI.id)
	// const findedPNR = inputOptions?.find(item => item.id === valuePNR.id)

	return (
		<div className='container projects-rm'>
			<div className='projects-rm__title title'>
				Отдел 2. Технические характеристики конструктивных решений и видов работ, учтённых в показателе
			</div>
			<div className='projects-table projects-rm__table'>
				<div className='projects-table__head projects-rm__table-head row'>
					<div className='projects-table__head-item col-4'>Наименование конструктивных решений и видов работ </div>
					<div className='projects-table__head-item col-4'>Краткие характеристики</div>
					<div className='projects-table__head-item col-4'>Стоимость из рабочей сметы ₽</div>
				</div>
				<div className='projects-table__body projects-rm__table-body'>
					{!!specArr?.length &&
						specArr.map((spec, index) => (
							<RMRowTable
								key={spec?.id || index}
								createSpecifications={createSpecifications}
								deleteSpecifications={deleteSpecifications}
								updateSpecifications={updateSpecifications}
								spec={spec}
								specArr={specArr}
								setSpecArr={setSpecArr}
								indexNewItem={index}
							/>
						))}
				</div>
				<div className='projects-rm__add'>
					<div
						className='flex-between-center'
						onClick={() => {
							const newArr = specArr.slice()
							newArr.push({ solution_name: '', price: '', specifications: '', project_id: selectedProject.id })
							setSpecArr(newArr)
						}}
					>
						<div className='add-icon'></div>
						<span className='color-blue'>Тех. характеристики</span>
					</div>
				</div>
			</div>
			<div className='projects-rm__footer container'>
				<div className='projects-rm__options row'>
					{/* <div className='col-3'>
						{tbSelect && (
							<Select
								menuPlacement='top'
								value={valueTB}
								onChange={setValueTB}
								options={tbSelect}
								placeholder='Временные здания и сооружения'
								styles={styleSelect}
								onBlur={() => {
									if (findedTB?.id && findedTB?.id !== selectedProject.temporary_buildings.id)
										editOption('temporary_buildings', findedTB.id)
								}}
							/>
						)}
					</div>
					<div className='col-3'>
						{piSelect && (
							<Select
								menuPlacement='top'
								styles={styleSelect}
								value={valuePI}
								onChange={setValuePI}
								options={piSelect}
								placeholder='Зимнее удорожание'
								onBlur={() => {
									if (findedPI?.id && findedPI?.id !== selectedProject.price_increase.id)
										editOption('price_increase', findedPI?.id)
								}}
							/>
						)}
					</div>
					<div className='col-3'>
						{pnrSelect && (
							<Select
								menuPlacement='top'
								styles={styleSelect}
								value={valuePNR}
								onChange={setValuePNR}
								options={pnrSelect}
								placeholder='ПНР'
								onBlur={() => {
									if (findedPNR?.id && findedPNR?.id !== selectedProject.pnr.id) editOption('pnr', findedPNR?.id)
								}}
							/>
						)}
					</div> */}
					<div className='col-3'>
						<input
							readOnly
							disabled
							type='text'
							title={valuePrice}
							value={prettyCost(+valuePrice / 1000) + ' ₽'}
							className='projects-rm__options-price'
						/>
					</div>
				</div>
				<div className='row projects-rm__submit'>
					<div className='projects-rm__submit-btn'>
						<Button text='Сформировать РМ' fullWidth onClick={getRM} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProjectsRM
