import React, { useCallback, useEffect, useRef } from 'react'
import { Button } from '../Button/Button'
import { IModal } from './Modal.model'
import './Modal.scss'

const Modal: React.FC<IModal> = ({ disableSubmit = false, title, children, submitText, setShowModal, onSubmit }) => {
	const closeModal = () => setShowModal(false)
	const wrapper = useRef<HTMLDivElement>(null)

	const outsideClickHandler = useCallback((e: any): void => {
		if (wrapper.current && e.target.closest('.modal') !== wrapper.current) {
			closeModal()
		}
	}, [])

	useEffect(() => {
		document.addEventListener('click', outsideClickHandler)
		return () => document.removeEventListener('click', outsideClickHandler)
	}, [outsideClickHandler])

	return (
		<div className='modal-blur'>
			<div ref={wrapper} className='modal'>
				<div className='modal__head'>
					<h4 className='modal__head-title title'>{title}</h4>
					<span className='modal__head-close-btn close-icon' onClick={closeModal} />
				</div>
				<div className='modal__content'>{children}</div>
				<div className='modal__submit'>
					<Button text={submitText} disabled={disableSubmit} fullWidth onClick={onSubmit} />
				</div>
			</div>
		</div>
	)
}

export { Modal }
