import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatUserName } from '../../../helpers/formatUserName'
import regexInputNcsCode from '../../../helpers/regexInputNcsCode'
import { updateProject } from '../../../redux/actions'
import { fileDelete, fileDownload, fileUpload } from '../../../redux/actions/filesAction/filesAction'
import { Routes } from '../../../router'
import { Button, Modal, TextInput } from '../../../shared/components'
import { UploaderFile } from '../../../shared/components/UploaderFile/UploaderFile'
import { IProjectEdit } from './ProjectEdit.model'
import './ProjectEdit.scss'

const ProjectEdit: React.FC<IProjectEdit> = ({ selectedProject, deleteProject }) => {
	const [showModal, setShowModal] = useState(false)
	const [titleProject, setTitleProject] = useState<string>(selectedProject.name)
	const [code, setCode] = useState<string>(regexInputNcsCode(selectedProject.ncs_code))
	const dispatch = useDispatch()

	const uploadFile = (key: string, file: File): void => {
		dispatch(fileUpload({ id: selectedProject.id, [key]: file }))
	}

	const [openPopup, setOpenPopup] = useState<boolean>(false)

	const wrapper = useRef<HTMLDivElement>(null)

	const outsideClickHandler = useCallback(
		(e): void => {
			if (openPopup && wrapper && !wrapper.current?.contains(e.target)) {
				setOpenPopup(false)
			}
		},
		[openPopup]
	)

	useEffect(() => {
		document.addEventListener('click', outsideClickHandler)
		return () => document.removeEventListener('click', outsideClickHandler)
	}, [outsideClickHandler])

	const routeToRM = Routes.selectedProjectRm.replace(/\:id/g, selectedProject.id.toString())

	return (
		<div className='project-edit container'>
			<div className='project-edit__head'>
				<h2 className='project-edit__head-title title'>Основная информация</h2>
				<div className='project-edit__head-actions'>
					<span className='edit-icon' onClick={() => setShowModal(true)} />
					{showModal && (
						<Modal
							title='Редактировать проект'
							setShowModal={setShowModal}
							disableSubmit={code.length < 12 || !titleProject}
							submitText='Сохранить'
							onSubmit={() => {
								dispatch(
									updateProject({ ncs_code: regexInputNcsCode(code), name: titleProject, id: selectedProject.id })
								)
								setShowModal(false)
							}}
						>
							<TextInput
								type='textarea'
								placeholder='Название проекта'
								value={titleProject}
								changeField={setTitleProject}
							/>
							<TextInput
								type='input'
								placeholder='Код показателя'
								value={regexInputNcsCode(code)}
								changeField={setCode}
							/>
						</Modal>
					)}
					<div className='basket-icon pos-rel' onClick={() => setOpenPopup(!openPopup)}>
						{openPopup && (
							<div className='popup' ref={wrapper}>
								<div className='project-edit__delete'>
									Вы действительно хотите удалить проект?
									<div className='project-edit__delete-actions'>
										<div className='link link-blue' onClick={() => setOpenPopup(false)}>
											Отменить
										</div>
										<div className='link link-blue' onClick={deleteProject}>
											Подтвердить
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className='project-edit__body row'>
				<div className='project-edit__body-item col-12'>
					<h4 className='sub-title'>Название</h4>
					<p>{selectedProject.name}</p>
				</div>
				<div className='project-edit__body-item col-2'>
					<h4 className='sub-title'>Код НЦС</h4>
					<p>{selectedProject.ncs_code}</p>
				</div>
				<div className='project-edit__body-item col-2'>
					<h4 className='sub-title'>Объекты</h4>
					<p>{selectedProject.object_count}</p>
				</div>
				<div className='project-edit__body-item col-2'>
					<h4 className='sub-title'>Исполнитель</h4>
					<p>{formatUserName(selectedProject.user_initials)}</p>
				</div>
			</div>
			<div className='project-edit__rm'>
				<div className='project-edit__rm-head flex-between-center'>
					<h2 className='project-edit__rm-title title'>
						РМ <span className='color-grey'>Не добавлено</span>
					</h2>
					<Link to={routeToRM}>
						<Button
							text='Добавить'
							disabled={
								!(
									(selectedProject.rm || selectedProject.rm_template) &&
									selectedProject.sm &&
									selectedProject.rv &&
									selectedProject.ws
								)
							}
						/>
					</Link>
				</div>
				<div className='project-edit__files flex-between'>
					<UploaderFile
						uploadedFile={selectedProject.rm || selectedProject.rm_template}
						fieldTitle='Шаблон РМ'
						nameField='rm'
						deleteHandler={
							selectedProject.rm && selectedProject.rm.type !== 'rm_template'
								? () => dispatch(fileDelete({ id: selectedProject.rm.id, field: selectedProject.rm.type }))
								: undefined
						}
						downloadHandler={() =>
							dispatch(
								fileDownload({
									id: selectedProject.rm?.id ?? selectedProject.rm_template.id,
									name: selectedProject.rm?.name ?? selectedProject.rm_template.name,
								})
							)
						}
						uploadHandler={uploadFile}
					/>
					<UploaderFile
						uploadHandler={uploadFile}
						deleteHandler={() => dispatch(fileDelete({ id: selectedProject.sm.id, field: selectedProject.sm.type }))}
						downloadHandler={() => dispatch(fileDownload({ id: selectedProject.sm.id, name: selectedProject.sm.name }))}
						nameField='sm'
						uploadedFile={selectedProject.sm}
						fieldTitle='СТ'
					/>
					<UploaderFile
						uploadHandler={uploadFile}
						deleteHandler={() => dispatch(fileDelete({ id: selectedProject.rv.id, field: selectedProject.rv.type }))}
						downloadHandler={() => dispatch(fileDownload({ id: selectedProject.rv.id, name: selectedProject.rv.name }))}
						nameField='rv'
						uploadedFile={selectedProject.rv}
						fieldTitle='РВ Базовая'
					/>
					<UploaderFile
						uploadHandler={uploadFile}
						deleteHandler={() => dispatch(fileDelete({ id: selectedProject.ws.id, field: selectedProject.ws.type }))}
						downloadHandler={() => dispatch(fileDownload({ id: selectedProject.ws.id, name: selectedProject.ws.name }))}
						nameField='ws'
						uploadedFile={selectedProject.ws}
						fieldTitle='РС'
					/>
				</div>
			</div>
		</div>
	)
}

export default ProjectEdit
