import { AxiosInstance } from 'axios'
import { IUser } from '../redux/redusers/authReducer/auth.model'
import { authorizeApi, modeURL } from './index'

const interseptor = (root: AxiosInstance) => {
	let isAlreadyFetchingAccessToken: boolean = false
	let subscribers: Array<(access_token: string) => void> = []

	const onAccessTokenFetched = (access_token: string): void => {
		subscribers = subscribers.filter(callback => callback(access_token))
	}

	const addSubscriber = (callback: (access_token: string) => void): void => {
		subscribers.push(callback)
	}

	root.interceptors.response.use(
		response => {
			return response
		},
		error => {
			const {
				config,
				response: { status },
			} = error
			const originalRequest = config

			if (status === 401) {
				if (!isAlreadyFetchingAccessToken) {
					isAlreadyFetchingAccessToken = true

					authorizeApi()
						.then(({ access_token, user }: { access_token: string; user: IUser }) => {
							originalRequest.headers.Authorization = `Bearer ${access_token}`
							root.defaults.headers.Authorization = `Bearer ${access_token}`
							localStorage.setItem('access_token', access_token)
							localStorage.setItem('user', JSON.stringify(user))
							isAlreadyFetchingAccessToken = false
							onAccessTokenFetched(access_token)
						})
						.catch(() => {
							localStorage.removeItem('access_token')
							localStorage.removeItem('user')
							window.location.href = modeURL
						})
				}

				const retryOriginalRequest = new Promise(resolve => {
					addSubscriber((access_token: string) => {
						originalRequest.headers.Authorization = `Bearer ${access_token}`
						root.defaults.headers.Authorization = `Bearer ${access_token}`
						localStorage.setItem('access_token', access_token)

						resolve(root(originalRequest))
					})
				})
				return retryOriginalRequest
			}

			return Promise.reject(error)
		}
	)
}

export default interseptor
