import { toast } from 'react-toastify'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { deleteFilesApi, downloadFileApi, updateFilesToProjectApi } from '../../../api'
import store from '../../../store'
import { setSelectedProject } from '../../actions'
import { FilesTypes } from '../../actions/filesAction/types'
import { ISelectedProject } from '../../actions/projectsAction/types'

function* fileUpload({ payload }: any) {
	try {
		yield toast.info(`Идёт загрузка файла...`)
		const body = new FormData()
		Object.entries(payload).forEach(([key, value]: [any, any]) => {
			if (key !== 'id') body.append(key, value)
		})

		const { data, status } = yield call(updateFilesToProjectApi, body, payload.id)

		if (status === 'success') {
			yield put(setSelectedProject(data))
			yield toast.success(`Файл загружен!`)
		}
	} catch (error) {
		yield toast.error(`Ошибка загрузки файла...`)
		console.log(error)
	}
}

function* fileDelete({ payload }: any) {
	try {
		const { status } = yield call(deleteFilesApi, payload.id)
		const project = { ...store.getState().projects.selectedProject, [payload.field]: null } as ISelectedProject

		if (status === 'success') {
			yield put(setSelectedProject(project))
			yield toast.warn(`Файл удален!`)
		}
	} catch (error) {
		yield toast.error(`Ошибка удаления файла!`)
		console.log(error)
	}
}

function* fileDowload({ payload }: any) {
	try {
		yield toast.success(`Cкачивание файла... ${payload.name}.xls`)
		yield call(downloadFileApi, payload.id, payload.name)
	} catch (error) {
		yield toast.error(`Ошибка скачивания файла ${payload.name}.xlsx`)
		console.log(error)
	}
}

export default function* watchFilesSaga() {
	yield takeEvery(FilesTypes.FILE_UPDATE, fileUpload)
	yield takeLatest(FilesTypes.FILE_DELETE, fileDelete)
	yield takeLatest(FilesTypes.FILE_DOWNLOAD, fileDowload)
}
