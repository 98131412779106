import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'

import { history } from '../router'
import { rootReducer } from '../redux/redusers'
import { rootSaga } from '../redux/sagas'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))

const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
