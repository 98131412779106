export enum FilesTypes {
	FILE_UPDATE = 'FILE_UPDATE',
	FILE_DELETE = 'FILE_DELETE',
	FILE_DOWNLOAD = 'FILE_DOWNLOAD',
}

export interface IFileUpdate {
	id: number
	[key: string]: File | any
}

export interface IFileDelete {
	id: number
	field: string
}

export interface IFileDownload {
	id: number
	name: string
}
