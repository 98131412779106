import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Routes } from '../../router'
import { selectorEdit } from './selector'

const ProjectEditHeader: React.FC = () => {
	const { ncsСode, id } = useSelector(selectorEdit)
	const { pathname } = useLocation()

	return (
		<>
			<Link
				to={!pathname.includes('rm') && id ? Routes.projects : Routes.selectedProject.replace(/\:id/g, id!)}
				className='arrow-icon'
			/>
			<h2 className='header__project-name title'>
				{!pathname.includes('rm') ? 'Проект' : 'Ресурсная модель'} <span>{ncsСode}</span>
			</h2>
		</>
	)
}

export default ProjectEditHeader
