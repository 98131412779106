import React from 'react'
import { IButton } from './Button.model'
import './Button.scss'

const Button: React.FC<IButton> = ({ text, onClick, fullWidth, disabled = false }) => (
	<button
		disabled={disabled}
		className={`btn ${fullWidth ? 'full-width' : ''} ${disabled ? 'disabled' : ''}`}
		onClick={onClick}
	>
		{text}
	</button>
)

export { Button }
