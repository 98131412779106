import { ReactText } from 'react'

export enum ProjectsType {
	GET_PROJECTS = 'GET_PROJECTS',
	GET_RM = 'GET_RM',
	GET_INPUT_OPTIONS = 'GET_INPUT_OPTIONS',
	SET_INPUT_OPTIONS = 'SET_INPUT_OPTIONS',
	EDIT_INPUT_OPTION = 'EDIT_INPUT_OPTION',
	SET_PROJECTS = 'SET_PROJECTS',
	CREATE_PROJECT = 'CREATE_PROJECT',
	DELETE_PROJECT = 'DELETE_PROJECT',
	GET_SELECT_PROJECT = 'GET_SELECT_PROJECT',
	SET_PROJECT_EDIT = 'SET_PROJECT_EDIT',
	UPDATE_PROJECT = 'UPDATE_PROJECT',
	PROJECTS_SEARCH = 'PROJECTS_SEARCH',
	LOADING_PROJECTS = 'LOADING_PROJECTS',
	SET_SEARCH = 'SET_SEARCH',
	ALLOW_MORE_PROJECTS = 'ALLOW_MORE_PROJECTS',
	APPEND_PROJECTS = 'APPEND_PROJECTS',
	SET_SPECIFICATIONS = 'SET_SPECIFICATIONS',
	GET_SPECIFICATIONS = 'GET_SPECIFICATIONS',
	CREATE_SPECIFICATIONS = 'CREATE_SPECIFICATIONS',
	DELETE_SPECIFICATIONS = 'DELETE_SPECIFICATIONS',
	UPDATE_SPECIFICATIONS = 'UPDATE_SPECIFICATIONS',
}

export interface ICreateProject {
	id?: ReactText
	ncs_code: string
	name: string
}

export interface InputOption {
	id?: number
	type: string
	index: string
	description: string
}

export interface ISpetification {
	solution_name: string
	specifications: string
	price: number | string
	project_id?: number
	id?: number | string
}
export interface RmTemplate {
	id: number
	name: string
	ext: string
	url?: any
	type: string
}

export interface TemporaryBuildings {
	id: number
	type: string
	index: string
	description: string
}

export interface PriceIncrease {
	id: number
	type: string
	index: string
	description: string
}

export interface Pnr {
	id: number
	type: string
	index: string
	description: string
}

export interface ISelectedProject {
	id: number
	name: string
	user_id: number
	ncs_code: string
	year: number
	rm_template: RmTemplate
	sm?: any
	rv?: any
	ws?: any
	rm?: any
	solution_name?: any
	specifications?: any
	price: number
	object_count: number
	temporary_buildings: TemporaryBuildings
	price_increase: PriceIncrease
	pnr: Pnr
	created_at: Date
	user_initials: string
}
