import { IUser } from '../../redusers/authReducer/auth.model'

enum AuthType {
	SET_AUTH = 'SET_AUTH',
	AUTHORIZE = 'AUTHORIZE',
	LOGOUT = 'LOGOUT',
}

const authorize = () => ({
	type: AuthType.AUTHORIZE,
})

const logout = () => ({
	type: AuthType.LOGOUT,
})

const setAuth = (accessToken: string, expires_in: number, user: IUser) => ({
	type: AuthType.SET_AUTH,
	accessToken,
	expires_in,
	user,
})

export { AuthType, authorize, setAuth, logout }
